import { Link } from "react-router-dom";

import classes from "./HomeLink.module.css";

const HomeLink = () => {
    const linkToHome = "/analytics/vod/dashboard";

    return (
        <div>
            <nav className={classes.homeNav}>
                <ul className={classes.homeNavLink}>
                    <Link to={linkToHome}>
                        <li>HOME</li>
                    </Link>
                </ul>
            </nav>
        </div>
    );
};

export default HomeLink;
