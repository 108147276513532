import React from "react";

import { convertDuration } from "../../scripts/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const myDateToString = (date) => {
    const d = new Date(date);

    // console.log("Date, d", date, d);

    let ret = "";
    ret += d.getDate();
    ret += ".";
    ret += d.getMonth() + 1;
    ret += ".";
    ret += d.getYear() + 1900;
    return ret;
};

const AssetPublishedAndCreatedInfo = ({ asset, assetStart, assetDuration }) => {
    // console.log("AssetPublishedAndCreatedInfo asset", asset);

    const assetCreated = assetStart;
    //const assetCreatedBy = "Matti Meikäläinen";
    //we should show this only if we know the name, which we don't, we need to request that info from Suite somehow

    // let assetCreator = 0;

    // if (asset) {
    //     assetCreator = asset.createdBy;
    // }

    return (
        <div>
            <p>
                <FontAwesomeIcon icon="hourglass-start" size="1x" /> {myDateToString(assetStart)} &nbsp; &nbsp;
                <FontAwesomeIcon icon="clock" size="1x" /> {convertDuration(assetDuration)} &nbsp; &nbsp; &nbsp; &nbsp;
                {"Created time: " + myDateToString(assetCreated)} &nbsp; &nbsp; &nbsp; &nbsp;
                {/* {"Creator: " + assetCreator} */}
            </p>
        </div>
    );
};

export default AssetPublishedAndCreatedInfo;
