import React from "react";
import moment from "moment";
import classes from "./AssetStatisticsHeading.module.css";

const AssetStatisticsHeading = (props) => {
    const getDateRange = () => {
        if (moment(props.startDate).format("DD MM YYYY") === moment(props.endDate).format("DD MM YYYY")) {
            return moment(props.startDate).format("DD.MM.YYYY");
        } else {
            return moment(props.startDate).format("DD.MM.YYYY") + " - " + moment(props.endDate).format("DD.MM.YYYY");
        }
    };

    return (
        <div>
            <h2 className={classes.periodName}>Asset statistics</h2>
            <p className={classes.dateRange}>{getDateRange()}</p>
        </div>
    );
};

export default AssetStatisticsHeading;
