import React from "react";

import classes from "./ValueCard2.module.css";

const ValueCard2 = (props) => {
    return (
        <div className={classes.resultItem2}>
            <div className={classes.amountNumber}>{props.value}</div>
            <div className={classes.resultItemTitle}>{props.title}</div>
        </div>
    );
};

export default ValueCard2;
