// import PropTypes from "prop-types";

import classes from "./MySelect.module.css";

const MySelect = ({ options, value, defaultValue, onChange }) => {
    // const textColor = color !== "yellow" ? "white" : "black";

    return (
        // <button onClick={onClick} style={{ color: textColor, backgroundColor: color }} className={classes.btn}>
        //     {text}
        // </button>
        <select className={classes.select} value={value} onChange={onChange}>
            {options && options.map((opt) => <option value={opt.value}>{opt.label}</option>)}
        </select>
    );
};

// Button.defaultProps = {
//     color: "steelblue",
// };

// Button.propTypes = {
//     text: PropTypes.string,
//     color: PropTypes.string,
//     onClick: PropTypes.func,
// };

export default MySelect;
